import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";

import SearchBlock from "components/search/SearchBlock";
import Captcha from "components/common/Captcha";
import gql from "graphql-tag";
import client from "lib/ApolloClient";
import { autobind } from "react-decoration";
import { page } from "components/page";
import { navigate } from "gatsby";


const getContactTypeList = gql`
  query getContactTypeList {
    result: getContactTypeList {
      list{
        sn
        name
        enName
      }
    }
  }
`;

const addContact = gql`
  mutation addContact($form: ContactForm) {
    result: addContact(Input: $form) {
      success
      message
    }
  }
`;

@withI18next(["common"])
@page
class Contact extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      listContent : "",
      defautSelectValue : "",
      formData :{
        username : "",
        useremail :"",
        usertel : "",
        ctype : "",
        subject : "",
        content : "",
        captcha : ""
      },captchaRefetch : null
    };
    
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.getList(this.props)
  }


@autobind
getList(props) {
  
  client.jumperrwdClient
    .query({
      query: getContactTypeList
    })
    .then((res) => {
      //console.log("test!!!");
      let { result } = res.data;
      //console.log(result);

      //<td>{this.props.t("jumper.common.faq"+id)}</td>

      this.state.formData.ctype = result.list[0].sn;

      let options = result.list.map((field) => {
        return (
          <option value={field.sn}>{field.name}</option>
        );
      })
      let date = this.state.formData;
      date.ctype = result.list[0].sn.toString();
      this.setState({ listContent: options, defautSelectValue : result.list[0].sn.toString(),formData:date});

    });
}

@autobind
getCaptchaRefetch(refetch){
  this.setState({captchaRefetch:refetch});
}


@autobind
async addContact() {
  
  //let a = this.state.formData
  //delete a["username"]
  let result = await client.jumperrwdClient
    .mutate({
      mutation: addContact,
      variables: {
        form: 
          this.state.formData
      },
    })
    .then((data) => {
      return data;
    })
    .finally(() => {
      return null;
    });

    if(result.data.result.success==true){
      alert("送出成功！");
      let data = this.state.formData;
      for (const [key, value] of Object.entries(this.state.formData)) {
        data[key]=""
      }
      data["ctype"] = this.state.defautSelectValue
      this.setState({formData: data});

      this.state.captchaRefetch();
    }else{
      alert("送出失敗！");
    } 
}



handleChange(event) {
  event.preventDefault();
  //console.log('The link was clicked.');

  //console.log(event.target.id);
  //console.log(event.target.name);
  //console.log(event.target.value);
  
  //console.log("must="+event.target.getAttribute("must"));

  let data = this.state.formData;
  data[event.target.name] = event.target.value;
  this.setState({formData: data});

  //console.log(this.state.formData.username);
}

handleSubmit(event) {
  event.preventDefault();

  const mustField = ['subject','content','captcha'];

  for (const [key, value] of Object.entries(this.state.formData)) {
    console.log(`${key}: ${value}`);
    //console.log(mustField.includes(key));
    if(mustField.includes(key)==true && value==""){
      if(key=="subject"){
        alert("請輸入主題！");
        return false;
      }else if(key=="content"){
        alert("請輸入內容！");
        return false;
      }else if(key=="captcha"){
        alert("請輸入驗證碼！");
        return false;
      }
    }
  }
  //event.target.submit();
  this.addContact();
  
}


  render() {
    return (
      <Layout {...this.props}>
        <div className="main sp" id="center">
          <a class="accesskey" href="#aC" id="aC" accesskey="C" title="主要內容區">:::</a>
          <SearchBlock />
          <section className="main_content">
            <div className="container">
              
              <h2>{this.props.t("jumper.common.contact.titlebar")}</h2>
              <a href="javascript:history.back();" className="back">{this.props.t("hyint.common.table.back")}</a>

              <div className="detail_block">
                <div className="apply_form">
                    <p>{this.props.t("hyint.common.contact.msg")}</p>
                    <form onSubmit={this.handleSubmit} >
                        <div className="form_grid">
                            <h3>{this.props.t("hyint.common.contact.info")}</h3>
                            <p>{this.props.t("jumper.common.contact.replyinfo")}</p>
                            <div className="form_grp">
                                <label htmlFor="username" className="form_title">{this.props.t("hyint.common.contact.name")}</label>
                                <div className="form_content">
                                  <input id="username" name="username" value={this.state.formData.username} onChange={this.handleChange}/>
                                </div>
                            </div>
                            <div className="form_grp">
                                <label htmlFor="useremail" className="form_title">Email</label>
                                <div className="form_content">
                                  <input id="useremail" name="useremail" value={this.state.formData.useremail} onChange={this.handleChange}/>
                                </div>
                            </div>
                            <div className="form_grp">
                                <label htmlFor="usertel" className="form_title">{this.props.t("hyint.common.contact.phone")}</label>
                                <div className="form_content">
                                  <input id="usertel" name="usertel" value={this.state.formData.usertel} onChange={this.handleChange}/>
                                </div>
                            </div>
                        </div>
                        <div className="form_grid">
                            <h3>{this.props.t("hyint.common.contact.comments")}</h3>
                            <p><abbr className="necessary" title={this.props.t("jumper.common.contact.mistinfo")} >*</abbr>{this.props.t("jumper.common.contact.mistinfo")}</p>
                            <div className="form_grp">
                                <label for="" className="form_title">{this.props.t("hyint.common.contact.type")}<abbr className="necessary" title={this.props.t("jumper.common.contact.mistinfo")} >*</abbr></label>
                                <div className="form_content form_inline">
                                <select name='ctype' must="1" value={this.state.formData.ctype} onChange={this.handleChange} >{this.state.listContent}</select>
                                </div>
                            </div>
                            <div className="form_grp">
                                <label htmlFor="subject" className="form_title">{this.props.t("hyint.common.contact.subject")}<abbr className="necessary" title={this.props.t("jumper.common.contact.mistinfo")} >*</abbr></label>
                                <div className="form_content">
                                    <input maxlength="254" id="subject" name="subject" must="1" value={this.state.formData.subject} onChange={this.handleChange} required />
                                </div>
                            </div>
                            <div className="form_grp">
                                <label for="address" className="form_title">{this.props.t("hyint.common.contact.content")}<abbr className="necessary" title={this.props.t("jumper.common.contact.mistinfo")} >*</abbr></label>
                                <div className="form_content">
                                    <textarea name="content" must="1" cols="30" rows="10" value={this.state.formData.content} onChange={this.handleChange} required ></textarea>
                                </div>
                            </div>
                            <div className="form_grp">
                                <div className="form_title">{this.props.t("hyint.common.login.authcode")}</div>
                                <div className="form_content form_inline">
                                    <Captcha getRefetch={this.getCaptchaRefetch} t={this.props.t} />
                                    <input name="captcha" title={this.props.t("jumperrwd.common.captcha")} placeholder={this.props.t("hyint.common.login.authcode")} value={this.state.formData.captcha} onChange={this.handleChange} required />
                                </div>
                            </div>
                            <div className="btn_grp">
                                <button className="btn btn-reset" type="reset" name="resetContactBtn" >{this.props.t("hyint.admin.common.reset")}</button>
                                <button className="btn btn-submit" type="submit" name="addContactBtn" >{this.props.t("hyint.common.table.gosubmit")}</button>
                            </div>
                        </div>
                    </form>
                </div>
              </div>


            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default Contact;
